import React from 'react';
import { useParams } from 'react-router-dom';
import { getFuelTransactionsUrl } from '@holmanfm/lib/services/fuel';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { BASE_URL } from '@holmanfm/lib/common/environment';
import { getQueryFromFilters } from '@holmanfm/lib/hooks/fuel/util';
import { useFiltering } from '@holmanfm/lib/use-filtering';
import LinkText from '~/shared/components/text-link';
import ExcelIcon from '~/shared/icons/excel';

// Shared excel export function
// areas/fuel/transactions/transactions-list.jsx
// and
// areas/individual-assets/pagination-layout.jsx

const ExportExcel = props => {
  const { assetId } = props;
  const { filters } = useFiltering();
  const { childOrgId } = useParams();
  const { t } = useNSTranslation('fuel', 'fuel-transactions');

  const {
    minDate,
    maxDate,
    invoiceYearMonth,
    creditCard,
    orgNodeId,
  } = getQueryFromFilters(filters);

  const linkToExcel = `${BASE_URL}/v1${getFuelTransactionsUrl(
    minDate,
    maxDate,
    invoiceYearMonth,
    orgNodeId,
    childOrgId,
    'xlsx',
    creditCard,
    assetId
  )}`;

  return (
    <LinkText href={linkToExcel} target="_blank" rel="noopener noreferrer">
      {t('export-as')}:&nbsp;
      <ExcelIcon />
    </LinkText>
  );
};

export default ExportExcel;
