/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '@holmanfm/lib/util/moment';
import { purchaseTypeConvert } from '@holmanfm/lib/common/marketplace/asset';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { formatMoney } from '@holmanfm/lib/common/marketplace-helpers';
import makeStyles from '~/shared/components/makeStyles';
import Typography from '~/shared/components/atom/typography/typography';
import TabGroup from '~/areas/individual-assets/tab-group';

const useStyles = makeStyles(theme => ({
  columnGroup: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  group: {
    minWidth: 220,
    marginRight: theme.spacing(4),
  },
}));

const TabColumns = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.group}>{children}</div>;
};

TabColumns.propTypes = {
  children: PropTypes.node.isRequired,
};

const TabItems = ({ data, header, style }) => {
  const classes = useStyles();
  return (
    <div className={classes.columnGroup}>
      <Typography variant="h6">{header}</Typography>
      <Typography variant="body2">
        {style ? <span style={style}>{data || '—'}</span> : data || '—'}
      </Typography>
    </div>
  );
};

TabItems.propTypes = {
  data: PropTypes.node,
  header: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

TabItems.defaultProps = {
  style: undefined,
  data: undefined,
};

const FinancialTab = props => {
  const { asset, setHasDataUpdated, hasDataUpdated, parentOrg } = props;
  const { t } = useNSTranslation('individualAsset', 'financial');

  return (
    <div>
      <Typography variant="h5" style={{ padding: '24px 0' }}>
        {t('title')}
      </Typography>
      <TabGroup
        sectionTitle={t('full-asset-details')}
        layout="financial-details"
        data={asset}
        setHasDataUpdated={setHasDataUpdated}
        hasDataUpdated={hasDataUpdated}
        parentOrg={parentOrg}
        editable={false}
      >
        <>
          <TabColumns>
            <TabItems
              data={asset?.assetExtend?.leaseTerm365}
              header={t('lease-term')}
            />
            <TabItems data={asset?.monthsBilled} header={t('months-billed')} />
            <TabItems
              data={formatMoney(asset?.capCost)}
              header={t('cap-cost')}
            />
          </TabColumns>
          <TabColumns>
            <TabItems
              data={
                asset?.leaseContractStartDate
                  ? formatDate(asset?.leaseContractStartDate)
                  : null
              }
              header={t('lease-start-date')}
            />
            <TabItems data={asset?.mis} header={t('mis')} />
            <TabItems
              data={asset?.assetExtend?.residual365}
              header={t('residual')}
            />
          </TabColumns>
          <TabColumns>
            <TabItems
              data={
                asset?.leaseContractEndDate
                  ? formatDate(asset?.leaseContractEndDate)
                  : null
              }
              header={t('lease-end-date')}
            />
            <TabItems
              data={
                asset?.inServiceDate ? formatDate(asset?.inServiceDate) : null
              }
              header={t('in-service-date')}
            />
            <TabItems
              data={formatMoney(asset?.monRental)}
              header={t('monthly-invoice')}
            />
          </TabColumns>
          <TabColumns>
            <TabItems
              data={purchaseTypeConvert[asset?.thirdPartyLeaseType]}
              header={t('lease-type')}
            />
          </TabColumns>
        </>
      </TabGroup>
    </div>
  );
};

FinancialTab.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  setHasDataUpdated: PropTypes.func.isRequired,
  hasDataUpdated: PropTypes.bool.isRequired,
  parentOrg: PropTypes.bool,
};

FinancialTab.defaultProps = {
  parentOrg: false,
};

export default FinancialTab;
