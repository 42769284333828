import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import Typography from '~/shared/components/atom/typography';
import StyledDialog from '~/shared/components/styled-dialog';

const UpdateEstimateDialog = props => {
  const { open, closeDialog, estimateNumber } = props;
  const { t } = useNSTranslation('dealerPortal', 'payment-estimator');

  return (
    <StyledDialog
      isOpen={open}
      onClose={closeDialog}
      dialogTitle={t('update-estimate')}
      btnActions={{}}
    >
      <Typography variant="body2">
        {t('estimate-has-been-updated', { estimateNo: estimateNumber })}
      </Typography>
    </StyledDialog>
  );
};

UpdateEstimateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  estimateNumber: PropTypes.string.isRequired,
};

UpdateEstimateDialog.defaultProps = {};

export default UpdateEstimateDialog;
