import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import usePermissions from '@holmanfm/lib/hooks/use-permissions';
import Features from '@holmanfm/lib/lib-global';
import makeStyles from '~/shared/components/makeStyles';
import Typography from '~/shared/components/atom/typography/typography';
import LinkText from '~/shared/components/text-link';
import ChooseLayout from '~/areas/individual-assets/choose-layout';

const useStyles = makeStyles(theme => ({
  tabGroup: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.backgroundColors.tan}`,
    marginBottom: theme.spacing(3),
  },
}));

const TabGroup = ({
  children,
  sectionTitle,
  style,
  layout,
  data,
  setHasDataUpdated,
  hasDataUpdated,
  parentOrg,
  auxData,
  editable = true,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const classes = useStyles();
  const { t } = useNSTranslation('individualAsset', 'overview');
  const { canAccessFeature } = usePermissions();
  return (
    <div className={classes.tabGroup} style={style}>
      {editable && (
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Typography
            variant="h6"
            style={{ marginBottom: 16, marginRight: 16 }}
          >
            {sectionTitle}
          </Typography>
          {!parentOrg && canAccessFeature(Features.ASSETS.UPDATE) && (
            <LinkText onClick={() => setIsEditing(!isEditing)}>
              {t('edit')}
            </LinkText>
          )}
        </div>
      )}
      {isEditing ? (
        <ChooseLayout
          layout={layout}
          data={data}
          closeEditing={() => setIsEditing(!isEditing)}
          setHasDataUpdated={setHasDataUpdated}
          hasDataUpdated={hasDataUpdated}
          auxData={auxData}
        />
      ) : (
        <div style={{ display: 'flex' }}>{children}</div>
      )}
    </div>
  );
};

TabGroup.propTypes = {
  children: PropTypes.node.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  layout: PropTypes.string,
  data: PropTypes.shape({}).isRequired,
  setHasDataUpdated: PropTypes.func.isRequired,
  hasDataUpdated: PropTypes.bool.isRequired,
  parentOrg: PropTypes.bool,
  auxData: PropTypes.shape({}),
};

TabGroup.defaultProps = {
  style: undefined,
  layout: undefined,
  parentOrg: false,
  auxData: {},
};

export default TabGroup;
